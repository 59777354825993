import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from 'react-bootstrap'
import Img from "gatsby-image"
import ReactMarkdown from 'react-markdown'

const Page = ({ data }) => {
    const { spaces, description_pickler, image_pickler, description_montessori, workshops } = data.mdx.frontmatter

    return (
      <Layout>
        <SEO title="Description des pédagogies actives" />
        <Container className="bg-green-light rounded my-md-4 py-4 text-justify">
          <h2 className="text-green-dark text-center my-2">Les pédagogies actives : Montessori et Pikler-Loczy</h2>
          <h5>Dans cet espace d’accueil, deux espaces d’éveil ont été pensés :</h5>
          {
            spaces.map((space, index) => {
              if (index % 2 === 0) {
                return (
                  <Row className="align-items-center">
                    <Col className="align-items-center">
                      <ReactMarkdown parserOptions={{ commonmark: true }} source={space.description} />
                    </Col>
                    <Col>
                      <Img fluid={space.image.childImageSharp.fluid} alt={space.description}/>
                    </Col>
                  </Row>
                )
              } else {
                return (
                  <Row className="align-items-center">
                    <Col>
                      <Img fluid={space.image.childImageSharp.fluid} alt={space.description}/>
                    </Col>
                    <Col className="align-items-center">
                      <ReactMarkdown parserOptions={{ commonmark: true }} source={space.description} />
                    </Col>
                  </Row>
                )
              }
            })
          }

          <strong className="d-block mt-2">Des livres sur chacune de ces pédagogies actives (et d’autres) sont disponibles dans la bibliothèque des parents, mais en voici des résumés :)</strong>
          
          
          <Row className="align-items-center mt-4">
            <Col md={6}>
                <ReactMarkdown parserOptions={{ commonmark: true }} source={description_pickler} />
            </Col>
            <Col md={6}>
              <Img fluid={image_pickler.childImageSharp.fluid} alt="Motricité libre"/>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col>
            <ReactMarkdown parserOptions={{ commonmark: true }} source={description_montessori} />
            </Col>
          </Row>

          
            {
              workshops.map((workshop, index) => {
                if (index % 2 === 0) {
                  return (
                    <Row className="align-items-center">
                      <Col className="align-items-center">
                        <ReactMarkdown parserOptions={{ commonmark: true }} source={workshop.description} />
                      </Col>
                      <Col>
                        <Img fluid={workshop.image.childImageSharp.fluid} alt={workshop.description}/>
                      </Col>
                    </Row>
                  )
                } else {
                  return (
                    <Row className="align-items-center">
                      <Col>
                        <Img fluid={workshop.image.childImageSharp.fluid} alt={workshop.description}/>
                      </Col>
                      <Col className="align-items-center">
                        <ReactMarkdown parserOptions={{ commonmark: true }} source={workshop.description} />
                      </Col>
                    </Row>
                  )
                }
              })
            }
        </Container>
      </Layout>
    )
  }  

export default Page

export const pageQuery = graphql`
    query($id: String) {
        mdx(id: { eq: $id }) {
          body
          frontmatter {
            spaces {
              description
              image {
                childImageSharp {
                  fluid(maxHeight: 300) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            description_pickler
            image_pickler {
              childImageSharp {
                fluid(maxHeight: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            description_montessori
            workshops {
              description
              image {
                childImageSharp {
                  fluid(maxHeight: 300) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
    }`
